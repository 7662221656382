import React from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import logo from "../../../src/assets/img/logo.png";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";


import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";

const useStyles = makeStyles(styles);
const useStyles1 = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});


export default function Header(props) {
  const classes1 = useStyles1();
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({

    right: false
  });
  const [open, setOpen] = React.useState(false);

  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });

  const anchorRef = React.useRef(null);


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const doLogout = () => {
    localStorage.clear();
    history.push('/');
    window.location.reload();
  }
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

//  const list = (anchor) => (
//    <div
//      className={clsx(classes1.list, {
//        [classes1.fullList]: anchor === 'top' || anchor === 'bottom',
//      })}
//      role="presentation"
//      onClick={toggleDrawer(anchor, false)}
//      onKeyDown={toggleDrawer(anchor, false)}
//    >
//      <List>
//        {['Login'].map((text, index) => (
//          < ListItem button key={text} onClick={() => window.location.href = 'https://pi-dev.co.id/kalfor/login?appId=KAcBn3TOz2y3A0vykniRZMdgvZmnYfsNSnNK5A3i&baseUrl=' + window.location.href + '&expired=true'} >
//            <ListItemIcon>
//              {index === 0 && <DashboardIcon />}
//              {index === 1 && <AssignmentIcon />}
//              {index === 2 && <AccountBoxIcon />}
//            </ListItemIcon>
//
//            <ListItemText primary={text} />
//          </ListItem>
//        ))}
//      </List>
//
//    </div >
//  );

  return (
    <div>
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <div>
            <Link to={'/'}><img src={logo} alt="kalfor logo" style={{ height: 45 }} /></Link>
          </div>
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}


          </div>
        </Toolbar>
      </AppBar>
    </div>

//          <Hidden smDown implementation="css">
//
//            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
//              {({ TransitionProps, placement }) => (
//                <Grow
//                  {...TransitionProps}
//                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
//                >
//                  <Paper>
//
//                    <ClickAwayListener onClickAway={handleClose}>
//                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} style={{ padding: '0 !important', fontSize: 12 }}>
//                        <MenuItem style={{ padding: '0 !important', fontSize: 12 }} onClick={() => doLogout()}>Logout</MenuItem>
//                      </MenuList>
//                    </ClickAwayListener>
//                  </Paper>
//                </Grow>
//              )}
//            </Popper>
//
//
//            {
//              localStorage.getItem('token') ?
//
//                <Button
//                  ref={anchorRef}
//                  aria-controls={open ? 'menu-list-grow' : undefined}
//                  aria-haspopup="true"
//                  onClick={handleToggle}
//                  style={{
//                    color: 'white',
//                    borderWidth: 1,
//                    borderStyle: 'solid',
//                    borderColor: 'rgb(255, 255, 255)',
//                    borderRadius: 54,
//                    fontSize: 12,
//                    fontWeight: 600,
//                    textTransform: 'capitalize'
//                  }}
//                >
//                  {localStorage.getItem('name')}
//                </Button>
//                :
//                <Button className={classes.title} onClick={() => window.location.href = 'https://pi-dev.co.id/kalfor/login?appId=2&appSecret=wCqQtJQryJEd2J1IIMxZ7DokYB7qFx8UBjDpnGIU&baseUrl=' + window.location.href} >
//                  Login
//          </Button>
//
//            }
//
//
//
//          </Hidden>
//
//          <Hidden mdUp implementation="css">
//            {['right'].map((anchor) => (
//              <React.Fragment key={anchor}>
//                <Button onClick={toggleDrawer(anchor, true)} style={{ float: 'right', color: 'white' }}>
//                  <IconButton
//                    color="inherit"
//                    aria-label="open drawer"
//                    onClick={toggleDrawer(anchor, true)}
//
//                  >
//                    <Menu />
//                  </IconButton>
//                </Button>
//                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
//                </Drawer>
//              </React.Fragment>
//            ))}
//          </Hidden>
//        </Toolbar>
//      </AppBar>
//    </div>

  );
}
Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
