
import React, { useEffect, useState } from "react";
import api from 'config/api'
import { useHistory } from "react-router-dom";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import Chip from '@material-ui/core/Chip';
import LoadingOverlay from 'react-loading-overlay';


// Css & js Style
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import styleCss from "../../../src/assets/css/views/global.module.css"



//icon
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


const useStyles = makeStyles(styles);


export default function Dashboard() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [urlData, setUrlData] = useState("");
  const [alert, setAlert] = useState({
    isAlertOpen: false,
    alertState: 'success',
    AlertMessage: ''
  });

  const [isLogin, setIsLogin] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [dstate, setdstate] = useState({ isDelete: false, id: '', state: '' });
  const [isManual, setIsManual] = useState(false);

  const [dataTabel, setDataTabel] = React.useState([]);
  const [popupData, setPopupData] = React.useState({ dataTabel: [] });
  const [popupURL, setPopupURL] = React.useState({});
  const [dataResultDQ, setDataResultDQ] = React.useState(new Array(17));
  const [dataResultSM, setDataResultSM] = React.useState(new Array(17));
  const [datapopupDetail, setDataPopupDetail] = React.useState([]);
  const [isLoginDialog, setIsLoginDialog] = React.useState(false);
  const [idProgram, setIdProgram] = React.useState(0);
  const [nameProgram, setNameProgram] = React.useState(0);

  const [dataQualityList, setDataQualityList] = useState(
    [
      'Masukkan angka yang berada di luar rentang (terlalu besar, terlalu kecil, negatif)',
      'Masukkan data dengan jenis yang salah (mis., Masukkan karakter alfanumerik dan sebaliknya, atau karakter dan angka campuran).',
      'Masukkan tanggal yang seharusnya tidak valid (terlalu awal, terlambat, salah, tanggal Julian tidak valid) dan format tanggal yang salah (yaitu, salah memasukkan format bb / hh / tttt).',
      'Periksa tanggal 29 Februari di tahun bukan kabisat.',
      'Salah memasukkan informasi waktu (mis., Memutar detik dan menit, salah format jj: mm: dd).',
      'Sertakan koma dalam jumlah besar.',
      'Masukkan angka besar menggunakan notasi eksponensial.',
      'Masukkan dengan tanda baca yang salah. (Misalnya, masukkan Nomor Jaminan Sosial tanpa tanda hubung saat aplikasi mengharapkan tanda hubung disertakan.)',
      'Masukkan teks yang melanggar aturan case sensitive, jika berlaku.',
      'Masukkan terlalu banyak karakter dalam field melebihi batas.',
      'Biarkan nilai kosong dan cukup tekan enter, biarkan nilainya menjadi default (atau bukan default seperti kasusnya!).',
      'Masukkan karakter khusus, seperti /, \, $,%.',
      'Masukkan nama file yang tidak valid. Misalnya, nama file umumnya tidak boleh menyertakan karakter khusus seperti \, /,?,), (, ",, |. Jika aplikasi menyimpan informasi ke file dan meminta nama file, pastikan aplikasi tersebut menangani nama yang tidak valid.',
      'Apakah ada data kosong?',
      'Apakah ada redudansi data?',
      'Is the data updated?',
      'Apakah ada access control?'
    ]
  );

  const [dataSimplicity, setDataSimplicity] = useState([
    'Menguji ukuran, posisi, lebar, tinggi elemen.',
    'Pengujian pesan kesalahan yang ditampilkan.',
    'Menguji berbagai bagian layar.',
    'Menguji font apakah bisa dibaca atau tidak.',
    'Menguji layar dalam resolusi berbeda dengan bantuan zoom in dan zoom out seperti 640 x 480, 600x800, dll.',
    'Menguji keselarasan teks dan elemen lain seperti ikon, tombol, dll. Berada di tempat yang tepat atau tidak.',
    'Menguji warna font.',
    'Menguji warna pesan kesalahan, pesan peringatan.',
    'Menguji apakah gambar memiliki kejernihan yang baik atau tidak.',
    'Menguji keselarasan gambar.',
    'Pengujian ejaan.',
    'Menguji apakah antarmuka menarik atau tidak.',
    'Pengujian scrollbar menurut ukuran halaman, jika ada.',
    'Menguji bidang yang dinonaktifkan jika ada.',
    'Menguji Ukuran File.',
    'Menguji Heading apakah telah disejajarkan dengan benar atau tidak.',
    'Pengujian warna tautan.'

  ])

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const classes = useStyles();


  const onChangeUrl = (e) => {
    setUrlData(e.target.value)
  }





  function closeNotification() {
    setAlert({
      ...alert,
      isAlertOpen: false,

    })
  }
  function openNotification(condition, Message) {
    if (condition == 'success') {
      setAlert({
        isAlertOpen: true,
        alertState: 'success',
        AlertMessage: Message
      })
    } else {
      setAlert({
        isAlertOpen: true,
        alertState: 'danger',
        AlertMessage: Message
      })
    }
    setTimeout(function () {
      closeNotification()
    }
      .bind(this), 3000);
  }

  const goDetail = (status) => {
    if (status) {
      setIsManual(true)
    } else {
      let dataManual = { dataQuality: dataResultDQ, simplicity: dataResultSM }
      history.push({
        pathname: '/result',
        state: { manual: dataManual, recommendation: data.data.recommendation, result: data.data.calculate, detail: data.data.response, url: urlData, date: new Date(), hasData: true }
      })
    }
  }

  const checkData = (isPublic) => {

    if (!urlData) {
      openNotification('danger', 'URL tidak boleh kosong!')
      return;
    }
    let url = urlData.includes('http://') || urlData.includes('https://') ? urlData : 'http://' + urlData
    let datas = { url: url, token: null, type: isPublic }

    setIsLoading(true)
    api.checkData(datas)
      .then((res) => {
        setIsLoading(false)
        if (res.data.message == 'Success menilai') {
          setOpen(true)
          setData(res)
        } else {
          openNotification('danger', res.data.message)
        }



      })
      .catch((err) => {
        setIsLoading(false)
        if (err && err.response) {

          openNotification('danger', err.response.statusText)
        } else {

          openNotification('danger', 'Tidak bisa menghubungi server, mohon coba lagi')

        }
      })
  }

  const getDataProgram = () => {
    api.getDataProgram(localStorage.getItem('userId'))
      .then((res) => {

        let dt = res.data
        for (let i = 0; i < dt.length; i++) {
          if (dt[i].total == 0) {
            dt[i].finalScore = 0
          } else {
            dt[i].finalScore = (dt[i].performance + dt[i].data_quality + dt[i].simplicity + dt[i].guideline + dt[i].access_control + dt[i].broken_link + dt[i].responsive + dt[i].ssl) / dt[i].total
          }
        }
        setDataTabel(dt)
      })
      .catch((err) => {
        if (err && err.response) {
          openNotification('danger', err.response.statusText)
        } else {

          openNotification('danger', 'Tidak bisa menghubungi server, mohon coba lagi')
        }
      })
  }
  const createProgram = () => {
    let cData = {
      "name": popupData.name,
      "user_id": localStorage.getItem('userId')
    }
    api.createProgram(cData)
      .then((res) => {
        openNotification('success', 'Berhasil membuat program')
        getDataProgram()
        setPopupData({ ...popupData, show: false })
      })
      .catch((err) => {
        if (err && err.response) {
          openNotification('danger', err.response.statusText)
        } else {
          openNotification('danger', 'Tidak bisa menghubungi server, mohon coba lagi')
        }
      })
  }

  const deleteProgram = (id) => {

    api.deleteProgram(id.id)
      .then((res) => {
        openNotification('success', 'Berhasil Hapus Program')
        getDataProgram()
        setdstate({ ...dstate, isDelete: false })
      })
      .catch((err) => {
        if (err && err.response) {
          openNotification('danger', err.response.statusText)
        } else {
          openNotification('danger', 'Tidak bisa menghubungi server, mohon coba lagi')
        }
      })
  }

  const EditProgram = () => {
    let cData = {
      "id": popupData.idpopup,
      "name": popupData.name,
      "user_id": localStorage.getItem('userId')
    }

    api.editProgram(cData)
      .then((res) => {
        openNotification('success', 'Berhasil Perbaharui Program')
        getDataProgram()
        setPopupData({ ...popupData, show: false })
      })
      .catch((err) => {
        if (err && err.response) {
          openNotification('danger', err.response.statusText)
        } else {
          openNotification('danger', 'Tidak bisa menghubungi server, mohon coba lagi')
        }
      })
  }

  const deleteResult = (id) => {
    api.deleteResult(id.id)
      .then((res) => {
        openNotification('success', 'Berhasil Hapus URL')
        getDataProgramDetailById(idProgram)
        setdstate({ ...dstate, isDelete: false })
      })
      .catch((err) => {
        if (err && err.response) {
          openNotification('danger', err.response.statusText)
        } else {
          openNotification('danger', 'Tidak bisa menghubungi server, mohon coba lagi')
        }
      })
  }


  const doSaveResult = (isManual) => {
    let datas = data
    let performa =
      datas.calculate.website_performance.number_request_percent +
      datas.calculate.website_performance.page_load_percent +
      datas.calculate.website_performance.page_size_percent +
      datas.calculate.website_performance.respon_time_percent

    let dataQuality = dataResultDQ
    let completeness = dataQuality[0] === 'Ya' ? 2 : 0
    let uniqueness = dataQuality[1] === 'Ya' ? 2 : 0
    let countValidity = 0

    for (let i = 2; i < dataQuality.length - 2; i++) {
      countValidity = dataQuality[i] === 'Ya' ? countValidity + 1 : countValidity
    }


    let simply = dataResultDQ.filter((value) => 'Ya').length;
    let simplicity = simply <= 6 ? 3 : simply <= 13 && simply > 7 ? 6 : 10

    let validity = countValidity < 3 ? 1.2 : countValidity > 3 && countValidity <= 9 ? 2.4 : 4
    let timelines = dataQuality[15] === 'Ya' ? 2 : 0
    let acessControl = dataQuality[16] === 'Ya' ? 5 : 0
    let additional_guidelines = datas.calculate.additional_guidelines.result_percent
    let responsive = datas.calculate.responsive.result_percent
    let dqComb = completeness + uniqueness + validity + timelines


    datas.dataResultDQ = dataResultDQ
    datas.dataResultSM = dataResultSM

    let ccData = {
      "program_id": idProgram,
      "url": popupURL.name,
      "data_quality": dqComb,
      "access_control": acessControl,
      "responsive": responsive,
      "simplicity": simplicity,
      "guideline": additional_guidelines,
      "ssl": datas.calculate.ssl_certificate.result_percent,
      "broken_link": datas.calculate.brokenlink_checker.result_percent,
      "performance": performa,
      "is_manual": isManual,
      "recommendation": JSON.stringify(datas),
      "created": new Date()
    }
    setIsLoading(true)
    api.createProgramChild(ccData)
      .then((res) => {
        setPopupURL(false)
        setIsLoading(false)
        setIsLoginDialog(false)
        openNotification('success', "Berhasil simpan data")
        getDataProgramDetailById(idProgram)
        setIsManual(false);
        setValue(0)
      })
      .catch((err) => {
        if (err && err.response) {
          openNotification('danger', err.response.statusText)
        } else {
          openNotification('danger', 'Tidak bisa menghubungi server, mohon coba lagi')
        }
      })
  }


  const getDataProgramDetailById = (dt) => {
    api.getDataProgramDetailById(dt)
      .then((res) => {
        setPopupData({
          ...popupData,
          isCreate: false,
          show: true,
          name: dt.name,
          idpopup: dt.id,
        })
        setDataPopupDetail(res.data)
        getDataProgram();
      })
      .catch((err) => {
        if (err && err.response) {

          openNotification('danger', err.response.statusText)
        } else {

          openNotification('danger', 'Tidak bisa menghubungi server, mohon coba lagi')

        }
      })
  }

  const onChangeDQ = (e, index) => {
    let x = dataResultDQ
    x[index] = e.target.value
    setDataResultDQ(x)
  }

  const onChangeSM = (e, index) => {
    let x = dataResultSM
    x[index] = e.target.value
    setDataResultSM(x)
  }


  const goEdit = (dt, state) => {
    setIdProgram(dt.id + '')
    setNameProgram(dt.name)
    if (state == 'name') {
      setPopupData({
        isCreate: true,
        show: true,
        name: dt.name,
        idpopup: dt.id,
        isEdit: true

      })
    } else {

      getDataProgramDetailById(dt.id)
    }
  }

  const checkPopupURL = (st) => {
    if (!popupURL.name) {
      openNotification('danger', 'URL tidak boleh kosong!')
      return;
    }
    let url = popupURL.name.includes('http://') || popupURL.name.includes('https://') ? popupURL.name : 'http://' + popupURL.name
    let datas = { url: url, token: null, type: true }
    setDataResultDQ(new Array(17))
    setDataResultSM(new Array(17))

    setIsLoading(true)
    api.checkData(datas)
      .then((res) => {

        setIsLoading(false)
        if (res.data.message == 'Success menilai') {
          setData(res.data)

          setIsLoginDialog(true)
        } else {
          openNotification('danger', res.data.message)
        }



      })
      .catch((err) => {
        setIsLoading(false)
        if (err && err.response) {

          openNotification('danger', err.response.statusText)
        } else {

          openNotification('danger', 'Tidak bisa menghubungi server, mohon coba lagi')

        }
      })
  }

  const calculateManual = () => {
    let dataManual = { dataQuality: dataResultDQ, simplicity: dataResultSM }
    history.push({
      pathname: '/result',
      state: { isManual: isManual, manual: dataManual, recommendation: data.data.recommendation, result: data.data.calculate, detail: data.data.response, url: urlData, date: new Date(), hasData: true }
    })
  }

  const goReport = (cmd) => {
    let cmdparse = JSON.parse(cmd.recommendation)

    let datess = new Date(cmd.created)
    history.push({
      pathname: '/result',
      state: { isManual: cmd.isManual, manual: { dataQuality: cmdparse.dataResultDQ, simplicity: cmdparse.dataResultSM }, recommendation: cmdparse.recommendation, result: cmdparse.calculate, detail: cmdparse.response, url: cmd.url, date: datess, hasData: true }
    })
  }



  const renderPopupDetail = () => {
    return (
      <Dialog className={styleCss.modal}

        width={'lg'}
        fullWidth={true}
        open={popupURL.show}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" style={{ background: '#054737', color: 'white' }}>
          Tambah URL baru
        </DialogTitle>
        <DialogContent dividers={true} >
          <DialogContentText style={{ width: '100%' }}
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <div>
              <div className={styleCss.marginInput}>
                <GridContainer className={styleCss.gridcontainerBotom}>
                  <GridItem md={3} xs={12}>
                    <label>URL</label>
                  </GridItem>
                  <GridItem md={9} xs={12}>
                    <Input placeholder="URL" required name="URL" value={popupURL.name} onChange={(e) => setPopupURL({ ...popupData, name: e.target.value })} inputProps={{ 'aria-label': 'description' }} className={styleCss.widthInput} />
                  </GridItem>
                </GridContainer>
              </div>

              <div style={{ marginBottom: "20px", marginTop: "10px", float: "right" }}>

                <Button variant="contained" component="span" onClick={() => setPopupURL({ show: false, isCreate: true })} className={styleCss.buttonClose}>Kembali</Button>
                <Button variant="contained" component="span" onClick={() => checkPopupURL()} className={styleCss.buttonConfirm}>Simpan</Button>
              </div>
            </div>


          </DialogContentText>
        </DialogContent>

      </Dialog >

    )
  }
  const renderPopupData = () => {
    return (
      <Dialog className={styleCss.modal}

        width={'lg'}
        fullWidth={true}
        open={popupData.show}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" style={{ background: '#054737', color: 'white' }}>
          {popupData.isCreate ? 'Tambah Aplikasi' : nameProgram}
        </DialogTitle>
        <DialogContent dividers={true} >
          <DialogContentText style={{ width: '100%' }}
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <div>

              {
                popupData.isCreate ?
                  <div className={styleCss.marginInput}>
                    <GridContainer className={styleCss.gridcontainerBotom}>
                      <GridItem md={3} xs={12}>
                        <label>Nama</label>
                      </GridItem>
                      <GridItem md={9} xs={12}>
                        <Input placeholder="Nama" required name="nama" value={popupData.name} onChange={(e) => setPopupData({ ...popupData, name: e.target.value })} inputProps={{ 'aria-label': 'description' }} className={styleCss.widthInput} />
                      </GridItem>
                    </GridContainer>
                  </div>
                  :
                  <div className={styleCss.marginInput}>
                    <Button onClick={() => setPopupURL({ show: true, })} style={{ width: '140px', backgroundColor: '#054737', color: 'white', marginBottom: 20, borderRadius: '4px', borderStyle: 'solid' }}>Tambah URL</Button>
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell >Url</TableCell>
                            <TableCell >Nilai</TableCell>
                            {/* <TableCell >Test Ulang</TableCell> */}
                            <TableCell >Report</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {
                            datapopupDetail.map((dt, index) => {
                              return (

                                <TableRow >
                                  <TableCell >{index + 1}</TableCell>
                                  <TableCell >{dt.url}</TableCell>
                                  <TableCell >

                                    <Chip
                                      style={{ backgroundColor: '#4D8ECA' }}
                                      label={dt.performance + dt.data_quality + dt.simplicity + dt.guideline + dt.access_control + dt.broken_link + dt.responsive + dt.ssl}
                                      clickable
                                      color={'secondary'}
                                      size="small"
                                    />
                                  </TableCell>
                                  {/* <TableCell >Test ulang</TableCell> */}
                                  <TableCell >
                                    <IconButton aria-label="delete" onClick={() => goReport(dt)} size="medium" size="medium">
                                      <OpenInBrowserIcon fontSize="inherit" />
                                    </IconButton>
                                  </TableCell>
                                  <TableCell>

                                    <IconButton aria-label="delete" onClick={() => setdstate({ isDelete: true, state: 'popup', id: dt })} size="medium" size="medium">
                                      <DeleteIcon fontSize="inherit" style={{ color: 'red' }} />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              )
                            })
                          }
                          {
                            datapopupDetail.length == 0 ?
                              <TableRow >
                                <TableCell align="center" colSpan="5">Belum ada data</TableCell>


                              </TableRow> : ''
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>


                  </div>

              }

              <div style={{ marginBottom: "20px", marginTop: "10px", float: "right" }}>

                <Button variant="contained" component="span" onClick={() => setPopupData({ ...popupData, show: false, isCreate: true })} className={styleCss.buttonClose}>Kembali</Button>
                {
                  popupData.isCreate && popupData.isEdit ?
                    <Button variant="contained" component="span" onClick={() => EditProgram()} className={styleCss.buttonConfirm}>Simpan</Button> :
                    popupData.isCreate ?
                      <Button variant="contained" component="span" onClick={() => createProgram()} className={styleCss.buttonConfirm}>Simpan</Button> :
                      ''
                }
              </div>
            </div>


          </DialogContentText>
        </DialogContent>

      </Dialog >

    )
  }
  // const nextData =
  const renderDialog = () => {
    return (

      <Dialog
        open={open}
        width={'md'}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" style={{ background: '#054737', color: 'white' }}>
          {
            isManual ? 'Penilaian Lanjutan' :
              ''
          }
        </DialogTitle>



        {
          isManual ?
            <div>
              <DialogContent>
                <DialogContentText>

                  <AppBar position="static" color="default">
                    <Tabs value={value} onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      centered
                      aria-label="simple tabs example">
                      <Tab label="Data Quality" disabled={value == 0 ? false : true} />
                      <Tab label="Simplicity" disabled={value == 1 ? false : true} />
                    </Tabs>
                  </AppBar>

                  {
                    value == 0 ?
                      <ol style={{ textAlign: 'justify', marginTop: 20, marginLeft: '10px' }}>
                        {
                          dataQualityList.map((dq, index) => {
                            return (
                              <li style={{ color: 'black !important' }}>
                                <div >{dq}</div>
                                <RadioGroup key={'dq' + index} row aria-label="position" name="test" value={dataResultDQ[index]} onChange={(e) => onChangeDQ(e, index)}>
                                  <FormControlLabel
                                    value="Ya"
                                    control={<Radio color="primary" />}
                                    label="Ya"
                                    labelPlacement="end"
                                  />
                                  <FormControlLabel
                                    value="Tidak"
                                    control={<Radio color="primary" />}
                                    label="Tidak"
                                    labelPlacement="end"
                                  />

                                </RadioGroup>
                                < Divider />
                              </li>
                            )
                          })
                        }
                      </ol>
                      : value == 1 ?
                        <ol style={{ textAlign: 'justify', marginTop: 20, marginLeft: '10px' }}>
                          {
                            dataSimplicity.map((dq, index) => {
                              return (
                                <li >
                                  <div >{dq}</div>
                                  <RadioGroup row key={'sm' + index} defaultValue="" aria-label="" name="customized-radios" value={dataResultSM[index]} onChange={(e) => onChangeSM(e, index)}>
                                    <FormControlLabel
                                      value="Ya"
                                      control={<Radio color="primary" />}
                                      label="Ya"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      value="Tidak"
                                      control={<Radio color="primary" />}
                                      label="Tidak"
                                      labelPlacement="end"
                                    />
                                  </RadioGroup>
                                  < Divider />
                                </li>
                              )
                            })
                          }
                        </ol> : ''
                  }

                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color="primary" style={{ backgroundColor: 'white', color: 'black', marginBottom: 20, borderRadius: '4px', borderStyle: 'solid' }} onClick={() => value == 1 ? setValue(0) : isLogin ? setIsManual(false) : goDetail(false)}>
                  {value == 0 ? 'Batal' : 'Kembali'}
                </Button>
                <Button color="primary" style={{ backgroundColor: '#054737', color: 'white', marginBottom: 20, borderRadius: '4px', borderStyle: 'solid' }} onClick={() => value == 0 ? setValue(1) : calculateManual()} >
                  {value == 0 ? 'Selanjutnya' : 'Tampilkan Hasil'}
                </Button>
              </DialogActions>
            </div>
            :
            <div>
              <DialogContent>
                <div className={styleCss.successRegIcon}><CheckCircleIcon fontSize="inherit" /></div>
                <div>Berhasil melakukan penilaian secara otomatis, apakah anda ingin melakukan penilaian lanjutan untuk mengetahui nilai Kualitas Data dan Simplicity?</div>
              </DialogContent>
              <DialogActions>
                <Button color="primary" style={{ backgroundColor: '#054737', color: 'white', marginBottom: 20, borderRadius: '4px', borderStyle: 'solid' }} onClick={() => goDetail(false)}>
                  Lewati
          </Button>
                <Button color="primary" style={{ backgroundColor: '#054737', color: 'white', marginBottom: 20, borderRadius: '4px', borderStyle: 'solid' }} onClick={() => goDetail(true)} >
                  Penilaian Lanjutan
          </Button>
              </DialogActions>
            </div>
        }

      </Dialog>


    )
  }
  const renderDialogDelete = () => {
    return (
      <Dialog
        open={dstate.isDelete}
        width={'md'}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" style={{ background: '#054737', color: 'white' }}>
        </DialogTitle>
        {
          <div>
            <DialogContent>
              {/* <div className={styleCss.successRegIcon}><HighlightOffIcon fontSize="inherit" /></div> */}
              <div>Apakah anda yakin menghapus data?</div>
            </DialogContent>
            <DialogActions>
              <Button color="primary" style={{ backgroundColor: '#054737', color: 'white', marginBottom: 20, borderRadius: '4px', borderStyle: 'solid' }} onClick={() => dstate.state == 'popup' ? deleteResult(dstate.id) : deleteProgram(dstate.id)}>
                Ya
          </Button>
              <Button color="primary" style={{ backgroundColor: 'white', color: 'black', marginBottom: 20, borderRadius: '4px', borderStyle: 'solid' }} onClick={() => setdstate({ ...dstate, isDelete: false })}>
                Tidak
          </Button>
            </DialogActions>
          </div>
        }

      </Dialog >


    )
  }
  const renderDialogLogin = () => {


    return (

      <Dialog
        open={isLoginDialog}
        width={'md'}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" style={{ background: '#054737', color: 'white' }}>
          {
            isManual ? 'Penilaian Lanjutan' :
              ''
          }
        </DialogTitle>



        {
          isManual ?
            <div>
              <DialogContent>
                <DialogContentText>

                  <AppBar position="static" color="default">
                    <Tabs value={value} onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      centered
                      aria-label="simple tabs example">
                      <Tab label="Data Quality" disabled={value == 0 ? false : true} />
                      <Tab label="Simplicity" disabled={value == 1 ? false : true} />
                    </Tabs>
                  </AppBar>

                  {
                    value == 0 ?
                      <ol style={{ textAlign: 'justify', marginTop: 20, marginLeft: '10px' }}>
                        {
                          dataQualityList.map((dq, index) => {
                            return (
                              <li style={{ color: 'black !important' }}>
                                <div >{dq}</div>
                                <RadioGroup key={'dq' + index} row aria-label="position" name="test" value={dataResultDQ[index]} onChange={(e) => onChangeDQ(e, index)}>
                                  <FormControlLabel
                                    value="Ya"
                                    control={<Radio color="primary" />}
                                    label="Ya"
                                    labelPlacement="end"
                                  />
                                  <FormControlLabel
                                    value="Tidak"
                                    control={<Radio color="primary" />}
                                    label="Tidak"
                                    labelPlacement="end"
                                  />

                                </RadioGroup>
                                < Divider />
                              </li>
                            )
                          })
                        }
                      </ol>
                      : value == 1 ?
                        <ol style={{ textAlign: 'justify', marginTop: 20, marginLeft: '10px' }}>
                          {
                            dataSimplicity.map((dq, index) => {
                              return (
                                <li >
                                  <div >{dq}</div>
                                  <RadioGroup row key={'sm' + index} defaultValue="" aria-label="" name="customized-radios" value={dataResultSM[index]} onChange={(e) => onChangeSM(e, index)}>
                                    <FormControlLabel
                                      value="Ya"
                                      control={<Radio color="primary" />}
                                      label="Ya"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      value="Tidak"
                                      control={<Radio color="primary" />}
                                      label="Tidak"
                                      labelPlacement="end"
                                    />
                                  </RadioGroup>
                                  < Divider />
                                </li>
                              )
                            })
                          }
                        </ol> : ''
                  }

                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color="primary" style={{ backgroundColor: 'white', color: 'black', marginBottom: 20, borderRadius: '4px', borderStyle: 'solid' }} onClick={() => value == 1 ? setValue(0) : setIsManual(false)}>
                  {value == 0 ? 'Batal' : 'Kembali'}
                </Button>
                <Button color="primary" style={{ backgroundColor: '#054737', color: 'white', marginBottom: 20, borderRadius: '4px', borderStyle: 'solid' }} onClick={() => value == 0 ? setValue(1) : doSaveResult(true)} >
                  {value == 0 ? 'Selanjutnya' : 'Tampilkan Hasil'}
                </Button>
              </DialogActions>
            </div>
            :
            <div>
              <DialogContent>
                <div className={styleCss.successRegIcon}><CheckCircleIcon fontSize="inherit" /></div>
                <div>Berhasil melakukan penilaian secara otomatis, apakah anda ingin melakukan penilaian lanjutan untuk mengetahui nilai Kualitas Data dan Simplicity?</div>
              </DialogContent>
              <DialogActions>
                <Button color="primary" style={{ backgroundColor: '#054737', color: 'white', marginBottom: 20, borderRadius: '4px', borderStyle: 'solid' }} onClick={() => doSaveResult(false)}>
                  Simpan
          </Button>
                <Button color="primary" style={{ backgroundColor: '#054737', color: 'white', marginBottom: 20, borderRadius: '4px', borderStyle: 'solid' }} onClick={() => isLogin ? setIsManual(true) : setIsManual(true)}>
                  Penilaian Lanjutan
          </Button>
              </DialogActions>
            </div>
        }

      </Dialog>


    )
  }


  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsLogin(true)
      getDataProgram();
    }
  }, []);


  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner
        text='Sedang mengambil data...'
        style={{ color: 'white' }}
      >
      </LoadingOverlay>
      <Snackbar
        place="tc"
        color={alert.alertState}
        icon={alert.alertState == 'success' ? CheckIcon : ErrorIcon}
        message={alert.AlertMessage}
        open={alert.isAlertOpen}
        closeNotification={() => closeNotification()}
        close
      />
      {renderPopupDetail()}
      {renderPopupData()}
      {renderDialog()}
      {renderDialogLogin()}
      {renderDialogDelete()}
      <div style={{ textAlign: 'center', paddingTop: 80 }}>
        <div className={styleCss.containerTitleHome}>Penilaian Sistem Sehat</div>
        <div style={{ marginBottom: 80 }}>Cek kesehatan & Performa aplikasi, kemudian dapatkan tips untuk meningkatkan Performa, Kualitas Data & Guidelines. </div>
      </div>

      {
        isLogin ?
          <div className={styleCss.containerResult}>
            <Button onClick={() => setPopupData({ show: true, isCreate: true })} style={{ height: '46px', width: '100px', backgroundColor: '#054737', color: 'white', marginBottom: 20, borderRadius: '4px', borderStyle: 'solid' }}>Tambah</Button>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell >Nama Aplikasi</TableCell>
                    <TableCell >Nilai Total</TableCell>
                    <TableCell >URL</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {
                    dataTabel.map((dt, index) => {
                      return (
                        <TableRow >
                          <TableCell >{index + 1}</TableCell>
                          <TableCell >{dt.name}
                            <IconButton aria-label="edit" onClick={() => goEdit(dt, 'name')} size="medium">
                              <EditIcon fontSize="inherit" style={{ color: 'rgb(5, 71, 55)' }} />
                            </IconButton>

                          </TableCell>
                          <TableCell >

                            <Chip
                              style={{ backgroundColor: '#4D8ECA' }}
                              label={Math.round(dt.finalScore)}
                              clickable
                              color={'secondary'}
                              size="small"
                            />

                          </TableCell>
                          <TableCell >
                            <IconButton aria-label="edit" onClick={() => goEdit(dt)} size="medium">
                              <LinkIcon fontSize="inherit" style={{ color: 'rgb(5, 71, 55)' }} />
                            </IconButton>
                          </TableCell>

                          <TableCell>
                            <IconButton aria-label="delete" onClick={() => setdstate({ id: dt, isDelete: true, state: 'base' })} size="medium" size="medium">
                              <DeleteIcon fontSize="inherit" style={{ color: 'red' }} />
                            </IconButton>
                          </TableCell>

                        </TableRow>
                      )
                    })
                  }
                  {
                    dataTabel.length == 0 ?
                      <TableRow >
                        <TableCell align="center" colSpan="5">Belum ada data</TableCell>


                      </TableRow> : ''
                  }


                </TableBody>
              </Table>
            </TableContainer>

          </div>
          :
          <div style={{ textAlign: 'center' }}>
            <Input name="urlData" value={urlData} onChange={onChangeUrl} disableUnderline placeholder="Masukan URL HTTP / HTTPS" className={styleCss.searchUrl} style={{ paddingLeft: 5, paddingRight: 5 }} />
            <Button onClick={() => checkData(false)} className={styleCss.btnUrl}>Periksa</Button>
          </div>
      }
    </div>
  );
}
