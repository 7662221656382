/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";

import LibraryBooks from "@material-ui/icons/LibraryBooks";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import Result from "views/Result/Result.js";



const dashboardRoutes = [
  {
    path: "/beranda",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },

  {
    path: "/result",
    name: "Result",
    icon: LibraryBooks,
    component: Result,
    layout: "/admin"
  },
  // {
  //   path: "/tentang-kami",
  //   name: "Tentang Kami",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: TentangKami,
  //   layout: "/admin"
  // },


];

export default dashboardRoutes;
