import axios from 'axios';

const config = {
    headers: {
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
}



const base = 'https://syshealth.piarea.co.id/api'
const sso = 'https://pi-dev.co.id/kalfor/sso/api/';
axios.defaults.baseURL = base;

// return ( post example
//     axios.post('/checkout', args, config)
// );

const validateToken = () => {
    axios.defaults.baseURL = sso
    return (
        axios.get('user', {
            headers: {
                'Access-Control-Allow-Origin': '*',
                // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    )
}

const getDataProgram = (user_id) => {
    axios.defaults.baseURL = base

    return (
        axios.get('calculate-programs/?user_id=' + user_id, config)
    )
}

const getDataProgramDetailById = (id) => {
    axios.defaults.baseURL = base
    return (
        axios.get('results/?program_id=' + id, config)
    )
}


const checkData = (data) => {
    axios.defaults.baseURL = base
    return (
        axios.post('/kesehatan-sistem', data)
    )
}

const createProgram = (data) => {
    axios.defaults.baseURL = base
    return (
        axios.post('/programs/', data)
    )
}

const editProgram = (data) => {
    axios.defaults.baseURL = base
    return (
        axios.put('/programs/' + data.id + '/', data)
    )
}

const deleteProgram = (data) => {
    axios.defaults.baseURL = base
    return (
        axios.delete('/programs/' + data + '/')
    )
}





const getResult = (id) => {
    axios.defaults.baseURL = base
    return (
        axios.get('/results/?program_id=' + id, config)
    )
}


const deleteResult = (id) => {
    axios.defaults.baseURL = base
    return (
        axios.delete('/results/' + id + '/')
    )
}

const createProgramChild = (data) => {
    axios.defaults.baseURL = base
    return (
        axios.post('/results/', data)
    )
}

export default {

    checkData, validateToken, getDataProgram, getDataProgramDetailById,
    createProgram, createProgramChild, editProgram, deleteProgram, getResult, deleteResult
}
