
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Button from '@material-ui/core/Button';
import Chart from "react-apexcharts";
import "react-sweet-progress/lib/style.css";
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from '@material-ui/core/Chip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';



// Css & js Style
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import styleCss from "../../../src/assets/css/views/global.module.css"


//icon

import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';

const useStyles = makeStyles(styles);


export default function Result() {
    const history = useHistory();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [isTableShow, setIsTableShow] = useState([])
    const [chartv, setChartv] = useState({});
    const [alert, setAlert] = useState({
        isAlertOpen: false,
        alertState: 'success',
        AlertMessage: ''
    });
    const [data, setData] = useState({})

    const classes = useStyles();

    const tofixed = (val) => {
        return parseFloat(val.toFixed(2));
    }



    function routeTo(url, appId, appSecret) {
        let token = localStorage.getItem('token');

        if (appId) {
            history.push('/login' + '?appId=' + appId + '&appSecret=' + appSecret + '&baseUrl=' + url)
        } else {
            openNotification('danger', 'Aplikasi Belum Tersedia')
        }



    }

    function closeNotification() {
        setAlert({
            isAlertOpen: false,
            alertState: 'danger',
        })
    }
    function openNotification(condition, Message) {
        if (condition == 'success') {
            setAlert({
                isAlertOpen: true,
                alertState: 'success',
                AlertMessage: Message
            })
        } else {
            setAlert({
                isAlertOpen: true,
                alertState: 'danger',
                AlertMessage: Message
            })
        }
        setTimeout(function () {
            closeNotification()
        }
            .bind(this), 3000);
    }
    const settingData = () => {

        let datas = location.state;
        if (!datas) {
            history.push('/beranda')
            return
        }

        let dataQuality = datas.manual.dataQuality
        let completeness = datas.manual.dataQuality[0] == 'Ya' ? 2 : 0
        let uniqueness = datas.manual.dataQuality[1] == 'Ya' ? 2 : 0
        let countValidity = 0


        let listrecDataQ = [
            "Memperbaiki type data yang digunakan dan menentukan range nilai pada variabel tersebut",
            "Membuat validasi pada form input",
            "Membuat rule penulisan kalendar",
            "Memperbaiki sistem kalendar",
            "Membuat rule penulisan waktu",
            "Membuat fungsi untuk menampilkan comma untuk nilai yang besar pada sistem, ex menggunakan toLocalString pada variable Javascript ",
            "Membuat rule penulisan pada angka yang besar",
            "Membuat validasi pada form input, memberikan petunjuk penulisan pada form input",
            "Membuat error message pada form",
            "Membuat error message pada form; menentukan batasan pada form input",
            "Membuat tanda mandatory fill",
            "Membuat rule penulisan",
            "Membuat rule penulisan filename",
            "Melakukan pengisian ulang data",
            "Menghapus data yang sama",
            "Melakukan pembaruan secara berkala"
        ]
        let arrDataQuality = []
        if (datas.manual.dataQuality[0] !== 'Ya') arrDataQuality.push(listrecDataQ[13])
        if (datas.manual.dataQuality[1] !== 'Ya') arrDataQuality.push(listrecDataQ[14])

        for (let i = 2; i < dataQuality.length - 2; i++) {
            countValidity = dataQuality[i] == 'Ya' ? countValidity + 1 : countValidity
            if (dataQuality[i] !== 'Ya') { arrDataQuality.push(listrecDataQ[i - 2]) }

        }
        let validity = countValidity < 3 ? 1.2 : countValidity > 3 && countValidity <= 9 ? 2.4 : 4
        let timelines = dataQuality[15] == 'Ya' ? 2 : 0
        let acessControl = dataQuality[16] == 'Ya' ? 5 : 0



        if (dataQuality[15] !== 'Ya') arrDataQuality.push(listrecDataQ[15])


        let simply = datas.manual.simplicity.filter((value) => 'Ya').length;
        let simplicity = simply <= 6 ? 3 : simply <= 13 && simply > 7 ? 6 : 10


        let listrecSimplicity = [
            'Perbaiki posisi tampilan',
            'Membuat error message ketika exception condition muncul',
            'Lakukan pengentasan dari setiap jenis screen dan perbaiki',
            'Mengatur ukuran font minimal 12 px pada setidaknya 60% teks pada halaman',
            'Membuat website menjadi responsive',
            'Mengatur jarak icon agar mudah diklik meskipun menggunakan mobile device, membuat ukuran icon dan font mirip, mengatur white space pada halaman ',
            'Membuat contras ratio antara font dan backround (follow: https://material.io/design/usability/accessibility.html#color-contrast)',
            'Membuat contras ratio antara font dan backround (follow: https://material.io/design/usability/accessibility.html#color-contrast)',
            'Meningkatkan kualitas gambar, menggunakan format image next-gen seperti JPEG 2000, JPEG XR, and WebP; https://web.dev/uses-webp-images/)',
            'https://material.io/design/layout/responsive-layout-grid.html#grid-customization',
            'Menambahkan spell checker plugin pada website',
            'Perbaiki tampilan sesuai dengan kaidah user experience',
            'Mengatur ukuran scrollbar secara otomatis sesuai dengan device yang digunakan oleh user',
            'Lakukan pengetesan untuk field yang di disabled',
            'menggunakan format image next-gen seperti JPEG 2000, JPEG XR, and WebP; https://web.dev/uses-webp-images/), tidak menggunakan image dengan resolusi yang lebih besar daripada yang ditampilkan dilayar',
            'Mengatur ukuran headings sesuai dengan target user',
            'Membuat contras ratio antara font dan backround (follow: https://material.io/design/usability/accessibility.html#color-contrast)'
        ]
        let arrSimplicity = []
        for (let sim = 0; sim < datas.manual.simplicity.length; sim++) {
            if (datas.manual.simplicity[sim] !== 'Ya') arrSimplicity.push(listrecSimplicity[sim])
        }


        datas.performa = datas.result.brokenlink_checker.result_percent +
            datas.result.website_performance.number_request_percent +
            datas.result.website_performance.page_load_percent +
            datas.result.website_performance.page_size_percent +
            datas.result.website_performance.respon_time_percent +
            datas.result.ssl_certificate.result_percent;

        datas.dataQuality = completeness + uniqueness + validity + timelines + acessControl

        datas.guidelines = datas.result.additional_guidelines.result_percent + datas.result.responsive.result_percent + simplicity


        setChartv({
            series: [{
                name: 'Performa',
                data: [(tofixed(datas.result.brokenlink_checker.result_percent) * 100 / 15),
                tofixed((datas.result.website_performance.number_request_percent + datas.result.website_performance.page_load_percent + datas.result.website_performance.page_size_percent + datas.result.website_performance.respon_time_percent) * 100 / 20),

                tofixed((datas.result.ssl_certificate.result_percent * 100) / 20),
                    0, 0, 0, 0, 0
                ]
            },
            {
                name: 'Data Quality',
                data: [0,
                    0,
                    0,
                    tofixed(((completeness + uniqueness + validity + timelines) * 100) / 10),
                    tofixed((acessControl * 100) / 5),
                    0, 0, 0
                ]
            }, {
                name: 'Guidelines',
                data: [0,
                    0,
                    0,
                    0,
                    0,
                    tofixed(((datas.result.additional_guidelines.result_percent) * 100) / 10),
                    tofixed((datas.result.responsive.result_percent * 100) / 10),
                    tofixed((simplicity * 100) / 10)
                ]
            }
            ],
            options: {
                chart: {
                    height: 250,
                    type: 'radar',
                },

                xaxis: {
                    categories: ['Broken Link Check', 'Website Performance', 'SSL', 'Data Quality', 'Access Control', 'Additional Guides', 'Responsive', 'Simplicity']
                }
            }



        })



        let detailResult = [];
        let brokenresult = {}
        let brokenRec = datas.recommendation.brokenlink_checker

        if (datas.detail.brokenlink_checker.count_url_error < 20) {
            brokenresult = { type: "Performa", nama: 'Broken link Checker', status: 'Low', ket: 'Sistem Anda sudah baik. Untuk memastikan kualitas sistem diharapkan untuk mengecek sistem secara berkala.', recommendation: brokenRec }
        } else if (datas.detail.brokenlink_checker.count_url_error >= 20 && datas.detail.brokenlink_checker.count_url_error < 70) {
            brokenresult = { type: "Performa", nama: 'Broken link Checker', status: 'Medium', ket: 'Sistem Anda sudah cukup baik, namun masih terdapat beberapa link yang rusak.', recommendation: brokenRec }
        } else {
            brokenresult = { type: "Performa", nama: 'Broken link Checker', status: 'High', ket: 'Sistem Anda memiliki banyak link yang rusak.', recommendation: brokenRec }
        }
        detailResult.push(brokenresult)





        let poorCount = 0;
        let fairCount = 0;

        let lt = datas.result.website_performance.page_load / 1000
        let ltstat = lt >= 12 ? 'poor' : (lt >= 6 && lt < 12) ? 'fair' : 'good'
        fairCount = ltstat == 'fair' ? fairCount + 1 : fairCount
        poorCount = ltstat == 'poor' ? poorCount + 1 : poorCount

        let ps = datas.result.website_performance.page_size
        let psstat = ps >= 2500 ? 'poor' : (lt >= 1800 && lt < 2500) ? 'fair' : 'good'
        fairCount = psstat == 'fair' ? fairCount + 1 : fairCount
        poorCount = psstat == 'poor' ? poorCount + 1 : poorCount

        let nr = datas.result.website_performance.number_request
        let nrstat = nr >= 100 ? 'poor' : (lt >= 80 && lt < 100) ? 'fair' : 'good'
        fairCount = nrstat == 'fair' ? fairCount + 1 : fairCount
        poorCount = nrstat == 'poor' ? poorCount + 1 : poorCount

        let rt = datas.result.website_performance.respon_time
        let rtstat = rt > 2000 ? 'poor' : (lt >= 1500 && lt <= 2000) ? 'fair' : 'good'
        fairCount = rtstat == 'fair' ? fairCount + 1 : fairCount
        poorCount = rtstat == 'poor' ? poorCount + 1 : poorCount
        
        let perfoRec = []
        if (datas.recommendation.website_performance.number_request) {
            perfoRec.push(datas.recommendation.website_performance.number_request)
        }
        if (datas.recommendation.website_performance.page_load) {
            perfoRec.push(datas.recommendation.website_performance.page_load)
        }
        if (datas.recommendation.website_performance.page_size) {
            perfoRec.push(datas.recommendation.website_performance.page_size)
        }
        if (datas.recommendation.website_performance.respon_time) {
            perfoRec.push(datas.recommendation.website_performance.respon_time)
        }


        if (fairCount == 0 && poorCount == 0) {
            detailResult.push({ type: "Performa", nama: 'Website Perfomance', status: 'Low', ket: 'Sistem Anda telah memiliki performa yang baik.', recommendation: perfoRec })
        }
        else if (fairCount == 1 && poorCount == 0) {
            detailResult.push({ type: "Performa", nama: 'Website Perfomance', status: 'Low', ket: 'Sistem Anda telah memiliki performa yang baik.', recommendation: perfoRec })
        }
        else if (fairCount == 2 && poorCount == 0) {
            detailResult.push({ type: "Performa", nama: 'Website Perfomance', status: 'Medium', ket: 'Sistem Anda cukup memiliki performa yang baik.', recommendation: perfoRec })
        }
        else {
            detailResult.push({ type: "Performa", nama: 'Website Perfomance', status: 'High', ket: 'Sistem Anda perlu perbaikan dan peningkatan performa.', recommendation: perfoRec })
        }


        let sslRec = []
        if (datas.recommendation.ssl_certificate.responsive)
            sslRec.push(datas.recommendation.ssl_certificate.responsive)
        if (datas.detail.ssl_certificate.res_ssl) {
            detailResult.push({ type: "Performa", nama: 'SSL Certificate availability', status: 'Low', ket: 'Keamanan sistem telah dilengkapi dengan SSL certificate.', recommendation: sslRec })
        } else {
            detailResult.push({ type: "Performa", nama: 'SSL Certificate availability', status: 'High', ket: 'Sistem memerlukan sistem keamanan. Harap menambahkan SSL certificate pada sistem Anda.', recommendation: sslRec })
        }
        let resRec = []
        if (datas.recommendation.responsive.responsive)
            resRec.push(datas.recommendation.responsive.responsive)

        if (datas.detail.responsive.res_responsive) {
            detailResult.push({ type: "Guidelines", nama: 'Responsive Web Design', status: 'Low', ket: 'Sistem dapat diakses dengan baik diberbagai jenis perangkat.', recommendation: resRec })
        } else {
            detailResult.push({ type: "Guidelines", nama: 'Responsive Web Design', status: 'High', ket: 'Sistem perlu diperbaiki agar dapat digunakan dengan optimal diberbagai perangkat.', recommendation: resRec })
        }

        //manual
        let access_control = 0
        let accC = []
        if (acessControl == 0) {
            accC.push('Menambahkan akses kontrol sesuai dengan kewenangan user')
        }
        if (acessControl == 5) {
            detailResult.push({ type: "Data Quality", nama: 'Access Control', status: 'Low', ket: 'Sudah terdapat user level system pada sistem Anda.', recommendation: accC })
        } else {
            detailResult.push({ type: "Data Quality", nama: 'Access Control', status: 'High', ket: 'Perlu dikembangkan user level system pada sistem Anda. ', recommendation: accC })
        }




        //data quality
        let dqualityrec = []

        let dquality = datas.dataQuality
        if (dquality <= 4) {
            detailResult.push({ type: "Data Quality", nama: 'Data Quality', status: 'High', ket: 'Kualitas data dalam sistem Anda perlu dicek dan diperbaiki.', recommendation: arrDataQuality })
        } else if (dquality > 4 && dquality <= 7) {
            detailResult.push({ type: "Data Quality", nama: 'Data Quality', status: 'Medium', ket: 'Kualitas data dalam sistem Anda cukup baik, namun terdapat beberapa poin yang perlu dicek dan diperbaiki.', recommendation: arrDataQuality })
        } else {
            detailResult.push({ type: "Data Quality", nama: 'Data Quality', status: 'Low', ket: 'Kualitas data dalam sistem Anda baik.', recommendation: arrDataQuality })
        }


        //guidelines
        let daddGuide = datas.recommendation.additional_guidelines

        if (datas.detail.additional_guidelines.count_guidelines >= 9) {
            brokenresult = { type: "Guidelines", nama: 'Additional Guidelines', status: 'Low', ket: 'Sistem sudah memenuhi kriteria standar dalam e-government system.', recommendation: daddGuide }
        } else if (datas.detail.brokenlink_checker.count_url_error >= 20 && datas.detail.brokenlink_checker.count_url_error < 70) {
            brokenresult = { type: "Guidelines", nama: 'Additional Guidelines', status: 'Medium', ket: 'Sistem cukup kriteria standar dalam e-government system, namun terdapat beberapa kriteria yang perlu dilengkapi.', recommendation: daddGuide }
        } else {
            brokenresult = { type: "Guidelines", nama: 'Additional Guidelines', status: 'High', ket: 'Sistem perlu dilengkapi dengan beberapa kriteria e-government.', recommendation: daddGuide }
        }
        detailResult.push(brokenresult)

        // simplicity

        let simplicityresult = simplicity
        if (simplicityresult <= 3) {
            detailResult.push({ type: "Guidelines", nama: 'Simplicity', status: 'High', ket: 'Sistem Anda membutuhkan perbaikan di sisi UI dan UX.', recommendation: arrSimplicity })
        } else if (simplicityresult > 4 && simplicityresult < 8) {
            detailResult.push({ type: "Guidelines", nama: 'Simplicity', status: 'Medium', ket: 'Sistem Anda sudah cukup baik. Namun, memerlukan beberapa perbaikan di sisi UI dan UX.', recommendation: arrSimplicity })
        } else {
            detailResult.push({ type: "Guidelines", nama: 'Simplicity', status: 'Low', ket: 'Sistem Anda sudah baik', recommendation: arrSimplicity })
        }


        datas.table = detailResult

        let newarr = new Array(detailResult.length)

        newarr.map((d) => false)

        setIsTableShow(newarr)



        setData(datas)
    }

    const setIsShow = (index) => {
        let x = isTableShow

        x[index] = !x[index]
        setIsTableShow(x)
    }
    useEffect(() => {
        settingData()
        setTimeout(function () {
            setIsLoading(false)
        }, 3000);
    }, []);


    return (
        <div>

            <Snackbar
                place="tc"
                color={alert.alertState}
                icon={alert.alertState == 'success' ? CheckIcon : ErrorIcon}
                message={alert.AlertMessage}
                open={alert.isAlertOpen}
                closeNotification={() => closeNotification()}
                close
            />

            {
                isLoading ?
                    <div className={styleCss.containerResult}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <Skeleton variant="rect" width="100%">
                                        <div style={{ height: 50 }} />
                                    </Skeleton>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card>
                                    <Skeleton variant="rect" width="100%">
                                        <div style={{ height: 280 }} />
                                    </Skeleton>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card>
                                    <Skeleton variant="rect" width="100%">
                                        <div style={{ height: 280 }} />
                                    </Skeleton>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card>
                                    <Skeleton variant="rect" width="100%">
                                        <div style={{ height: 280 }} />
                                    </Skeleton>
                                </Card>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <Skeleton variant="rect" width="100%">
                                        <div style={{ height: 280 }} />
                                    </Skeleton>
                                </Card>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <Skeleton variant="rect" width="100%">
                                        <div style={{ height: 400 }} />
                                    </Skeleton>
                                </Card>
                            </GridItem>
                        </GridContainer>

                    </div>
                    :
                    <div className={styleCss.containerResult}>
                        <div onClick={() => history.push('/beranda')} style={{ fontSize: 20, cursor: 'pointer' }}>
                            <Button style={{ backgroundColor: '#054737', color: 'white', marginBottom: 20, borderRadius: '4px', borderStyle: 'solid' }}><ArrowBackIcon /> Kembali</Button>
                        </div>
                        <h2 style={{ textAlign: 'center' }}>HASIL PENILAIAN HALAMAN</h2>
                        <Card>
                            <CardBody>
                                <a className={styleCss.resultHeaderUrl} href={data.url}>{data.url}</a>
                                <div className={styleCss.resultHeaderDate}>Tes dilakukan pada {data.date.toLocaleString()}</div>
                            </CardBody>
                        </Card>
                        <GridContainer>
                            <GridItem md={4} xs={12}>
                                <Card>
                                    <CardBody className={styleCss.dataResultContainer}>
                                        <div className={styleCss.dataResultText}>
                                            <DonutSmallIcon />Performa
                                    </div>
                                        <div className={styleCss.dataResultValue}>
                                            {Math.round(data.performa * 100 / 55)}
                                        </div>
                                        {/* <div>
                                            <LinearProgress variant="determinate" value={data.performa} />
                                        </div> */}
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem md={4} xs={12}>
                                <Card>
                                    <CardBody className={styleCss.dataResultContainer}>
                                        <div className={styleCss.dataResultText}>
                                            <ViewQuiltIcon />Data Quality
                                    </div>
                                        <div className={styleCss.dataResultValue}>
                                            {Math.round(data.dataQuality * 100 / 15)}
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem md={4} xs={12}>
                                <Card>
                                    <CardBody className={styleCss.dataResultContainer}>
                                        <div className={styleCss.dataResultText}>
                                            <VerticalSplitIcon />Guidelines
                                    </div>
                                        <div className={styleCss.dataResultValue}>
                                            {Math.round(data.guidelines * 100 / 30)}
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                        <Card>
                            <CardBody>
                                <GridContainer style={{ height: 300 }}>
                                    <GridItem md={7} xs={12}>
                                        <div>
                                            <div className={styleCss.dataResultTotalTitle}> Total Akhir</div>
                                            <div className={styleCss.dataResultTotalValue}>{data.guidelines + data.dataQuality + data.performa} / 100</div>
                                        </div>
                                        <div>
                                            <LinearProgress variant="determinate" value={data.guidelines + data.dataQuality + data.performa} style={{ color: 'red' }} />
                                        </div>
                                    </GridItem>
                                    <GridItem md={5} xs={12}>
                                        <Chart options={chartv.options} series={chartv.series} type="radar" height={300} />
                                    </GridItem>
                                </GridContainer>



                            </CardBody>
                        </Card>

                        <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell >Parameter Penilaian</TableCell>
                                        <TableCell >Kategori</TableCell>
                                        <TableCell>Prioritas</TableCell>
                                        <TableCell >Kesimpulan</TableCell>
                                    </TableRow>
                                </TableHead>


                                {
                                    data.table.map((d, index) => {
                                        let showtable = false
                                        const goChange = (index) => {
                                            let x = isTableShow
                                            x[index] = !x[index]
                                            setIsTableShow(x)
                                        }
                                        return (
                                            <TableBody key={'tbody' + index}>
                                                <TableRow onClick={() => goChange(index)} >

                                                    <TableCell >{d.nama}</TableCell>
                                                    <TableCell >{d.type}</TableCell>
                                                    <TableCell align='center'>
                                                        <Chip
                                                            style={{ backgroundColor: d.status === 'High' ? '#ff6961' : d.status === 'Medium' ? '#ffcc33' : "#66cc66" }}
                                                            label={d.status}
                                                            clickable
                                                            color={'secondary'}

                                                            size="small"

                                                        />
                                                    </TableCell>
                                                    <TableCell >{d.ket}</TableCell>
                                                </TableRow>
                                                {
                                                    d.recommendation.length !== 0 ?
                                                        <TableRow  >
                                                            <TableCell></TableCell>
                                                            <TableCell colSpan="3">
                                                                <Table className={classes.table}>
                                                                    <TableHead padding="none" style={{ padding: '0 !important' }}>
                                                                        <TableRow padding="none">
                                                                            <TableCell padding="none"> <LibraryBooksIcon /> <b>Rekomendasi</b></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    {
                                                                        d.recommendation.map((rcm, index2) => {
                                                                            return (
                                                                                <TableRow >
                                                                                    <TableCell>{rcm}</TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        })
                                                                    }
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>

                                                        : ''
                                                }


                                            </TableBody>
                                        )
                                    })
                                }





                            </Table>
                        </TableContainer>
                    </div>

            }

        </div >
    );
}
