import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";

import api from "../config/api";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";


let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    {/* if() */}
    <Redirect from="/" to="/beranda" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions

  const [mobileOpen, setMobileOpen] = React.useState(false);



  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  
  async function checker() {
    let token = localStorage.getItem('token')
    let url = window.location.href.split('?authkey=')
    let urlFromToken = url[1]
    if (urlFromToken) {
      localStorage.setItem('token', urlFromToken)
      await validateToken(true);
    } else {
      if (token) {
        validateToken();
      }
    }
  }
  const validateToken = (isEarly) => {
    return api.validateToken()
      .then((res) => {

        localStorage.setItem('email', res.data.email)
        localStorage.setItem('name', res.data.name)
        localStorage.setItem('roles', JSON.stringify(res.data.roles))
        localStorage.setItem('userId', res.data.id)

        if (isEarly) {
          let url2 = window.location.href.split('?authkey=')
          setTimeout(

            window.location.href = url2[0]
            , 3000);
        } else {
          
        }



      })

      .catch((err) => {
        localStorage.clear();
        window.location.reload()
        // window.location.href = 'https://pi-dev.co.id/kalfor/login?appId=1&appSecret=KAcBn3TOz2y3A0vykniRZMdgvZmnYfsNSnNK5A3i&baseUrl=' + window.location.href + '&expired=true';


      })
  }
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    checker();
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>

      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}

        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes}</div>
        </div>

        {getRoute() ? <Footer /> : null}

      </div>
    </div>
  );
}
